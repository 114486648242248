/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Stelica's Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Stelica Paval Portfolio",
    type: "website",
    url: "http://stelipaval.com/",
  },
};

//Home Page
const greeting = {
  title: "Stelica Paval",
  logo_name: "Stelica Paval",
  nickname: "Full Stack Developer",
  subTitle:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  resumeLink:
    "https://drive.google.com/",
  portfolio_repository: "https://github.com/dorinpaval",
  githubProfile: "https://github.com/dorinpaval",
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/dorinpaval",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/stelian-paval/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:paval.stelica@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/dorin.paval.1/",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  }
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using Angular & React-Redux",
        "⚡ Developing mobile applications using React Native and solo android apps using Kotlin",
        "⚡ Creating application backend in Node, Express & ASP.NET Core",
      ],
      softwareSkills: [
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Angular",
          fontAwesomeClassname: "simple-icons:angular",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "C#",
          fontAwesomeClassname: "simple-icons:csharp",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⚡ Deploying deep learning models on cloud to use on mobile devices",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Photoshop",
          fontAwesomeClassname: "simple-icons:adobephotoshop",
          style: {
            color: "#001E36",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Inkscape",
          fontAwesomeClassname: "simple-icons:inkscape",
          style: {
            color: "#000000",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/layman_brother",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/ashutosh_1919",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "http://codeforces.com/profile/layman_brother",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@ashutosh391",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/laymanbrother",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Politehnica University of Bucharest",
      subtitle: "B.S. in Computer Science",
      logo_path: "iiitk_logo.png",
      alt_name: "UPB Logo",
      duration: "Oct 2013 - Jun 2017",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Heavy focus on full stack engineering with modules on both front and back-end processes ",
        "⚡ I was selected for Merit cum Means Scholarship which is given to top 10% of students in college. I have received award from respected director for consistently best performance in academics.",
      ],
      website_link: "https://upb.ro/en",
    },
    {
      title: "Maharishi International University",
      subtitle: "Master's in Software Development",
      logo_path: "miu-logo.png",
      alt_name: "MIU Logo",
      duration: "Jan 2022 - Jan 2023",
      descriptions: [
        "⚡ Mastering how web technologies work and have the ability to learn new ones as they arise",
        "⚡ Build full stack web applications at any scale",
        "⚡ Build a mobile app for any business idea",
        "⚡ Use the power of cloud computing and understand AWS Cloud services",
      ],
      website_link: "https://msd.miu.edu/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Certified Cloud Practitioner",
      subtitle: "- AWS",
      logo_path: "aws-logo.png",
      certificate_link:
        "https://www.credly.com/badges/b94826e4-a09c-499f-b6bd-e99a4ce966ef/linked_in_profile",
      alt_name: "AWS",
      color_code: "#FFC9BB",
    },
    {
      title: "ITIL Foundation",
      subtitle: "- PeopleCert",
      logo_path: "itil-logo.png",
      certificate_link:
        "https://www.credly.com/badges/b94826e4-a09c-499f-b6bd-e99a4ce966ef/linked_in_profile",
      alt_name: "ITIL",
      color_code: "#FFA5AB",
    },
    {
      title: "Modern React with Redux",
      subtitle: "- Stephen Grider",
      logo_path: "udemy-logo.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-a4cf5897-fee3-44a4-aca7-dbb58b358d53/",
      alt_name: "Udemy",
      color_code: "#BE93D4",
    },
  ],
};

// Experience Page
const experience = {
  title: "Work, Internship and Mentorship",
  subtitle: "",
  description:
    "Software and web development professional with 5+ years of experience delivering technical systems, solutions, and tools to business and private clients across diverse operational platforms internationally. Experienced in full system development lifecycle, including backend and frontend functions. I am working effectively in independent and team environments as well, across the phases of software development life cycle for front and back end applications.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Full Stack Developer",
          company: "Self-Employed",
          company_url: "",
          logo_path: "self.jpeg",
          duration: "Sept 2017 - Sept 2021",
          location: "Detroit, MI",
          description:
            `Analyzing customer requirements and create application accordingly
             -  Building APIs following RESTful standards to allow users to submit information by storing data in MongoDB / DynamoDB
             - Created accessible, fully responsive, and functional user interfaces to allow visitors on any device to have the same perfect user experience  `,
          color: "#610e53",
        },
        {
          title: "Software Engineer",
          company: "BearingPoint",
          company_url: "https://www.bearingpoint.com/en-us/",
          logo_path: "bearingpoint.png",
          duration: "Sept 2017 - Sept 2021",
          location: "Bucharest, Romania",
          description:
            ["Assessing project requirements using Agile & Scrum principles related to the critical single page application resulting in estimation of Level of effort (LOE) and required working hours",
            " - Developing object-oriented code with 100% accordance to industry and company standards",
            " - Maintaining newly developed and legacy systems internal web application using ASP.NET Web API 2, JavaScript and ES6"],
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Software Developer Intern",
          company: "Yardi Systems",
          company_url: "https://www.yardi.com/",
          logo_path: "yardi.jpeg",
          duration: "May 2017 - Jul 2017",
          location: "Bucharest, Romania",
          description:
            "I have worked on project of predicting freight rates based on previous data. There were two objectives: (1) To build a forecasting engine to predict daily freight rates. (2) To embed feature in the model which can explain the seasonal major changes in freight rate based on regions and locations. At the end of internship, I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
          color: "#ee3c26",
        },
      ],
    },
    {
      title: "Mentorships",
      experiences: [
        {
          title: "Teacher Assistant",
          company: "Maharishi International University",
          company_url: "https://msd.miu.edu",
          logo_path: "miu-logo.png",
          duration: "Mar 2022 - Dec 2022",
          location: "Fairfield, IA",
          description:
            "Grading homework, exams and working with weak students to better understand the topics. I have also helped students in their projects and assignments.",
          color: "#4285F4",
        },

        {
          title: "Programming instructor",
          company: "Logiscool",
          company_url:
            "https://www.logiscool.com/ro/",
          logo_path: "logiscool.png",
          duration: "Feb 2019 - Jan 2020",
          location: "Bucharest, Romania",
          description:
            "Teaching children (5-15 y.o.) basics of AI, Python and Unity. I have helped them understanding the concepts by playing games and doing exercises.",
          color: "#0C9D58",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create back-end and front-end projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: []

};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "picturedraw.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with ML, AI, React, Android, Cloud and Opensource Development.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "https://medium.com/@paval.stelica/aws-codestar-a0495785b59",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "17222 Brady, Redford Charter Twp, MI 48240, USA",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/DBiWJTwofPSYMZNs7",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "(313) 663-1221",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
